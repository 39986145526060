<template lang="pug">
  div
    .card-product-new
      .card-product-new__content

        .card-product-new__row.card-product-new__row-first
          user-card-small(:user="user")
          div
            i.el-icon-copy-document.button-menu(title="Скопировать ссылку на объявление" @click="copyToClipboard")

        .card-product-new__row.card-product-new__row-second
          .card-product-new__block-rating
            p рейтинг: {{user.trust_coef}}%
            p сделок: {{user.statistics.deals_count}}
          .d-flex
            span.card-product__icon-bank(v-for="(payment, index) in payments", :key="index")
              span.card-product__icon-payment(:style="iconBg(payment.icon)", :title="payment.title")

            span.card-product__icon-bank(v-show="hasManyPayments", title="показать все", v-popover:popoverPayments)
              span.card-product__icon-payment-more
                | +
            el-popover(
              placement="top",
              width="auto",
              trigger="hover",
              ref="popoverPayments",
            )
              .card-product-new__row.card-product-new__row-second
                .d-flex
                  span.card-product__icon-bank(v-for="(payment, index) in item.payments.slice(1)", :key="index")
                    span.card-product__icon-payment(:style="iconBg(payment.icon)", :title="payment.title")

        .card-product-new__row.card-product-new__row-thrid
          .card-product-new__price
            p от {{item.min}} до {{item.max}} {{item.currency.code}}
            span {{item.price}} {{item.currency.code}}

          button.btn.btn__main(@click="openDealCalculator()" v-if="canTrade")
            | {{btnLabel(item)}}

          el-popover(
            v-if="cantTrade",
            placement="top",
            width="auto",
            trigger="hover",
            ref="popoverReasons",
          )
            .card-product-new__row.card-product-new__row-second
              .d-flex.reasons-cant-trade
                span(v-for="reason in reasonsCantTrade") {{reason}}
          button.btn.btn__main.disabled(v-if="cantTrade", v-popover:popoverReasons)
            | {{btnLabel(item)}}


</template>

<script>
import UserCardSmall from './Partials/UserCardSmall';
import card from './Mixins/card';
import analytics from '@/components/mixins/analytics';

export default {
  name: 'AdCard',

  components: {
    UserCardSmall,
  },

  mixins: [
    card,
    analytics
  ],

  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    //
    canTrade() {
      return this.item.hasOwnProperty('is_deal_available') ? this.item.is_deal_available.result : true;
    },

    //
    cantTrade() {
      return !this.canTrade;
    },

    //
    reasonsCantTrade() {
      return this.cantTrade
        ? this.item.is_deal_available.messages
        : [];
    }
  },
  methods: {
    openDealCalculator() {
      let category, action;
      if (this.item.is_sale) {
        category = 'Buy';
        action = 'buy'
      } else {
        category = 'Sell';
        action = 'sell';
      }
      this.sendAnalytics(category, action);
      this.sendAnalytics('Content', 'ViewContent', false, ['facebook']);
      this.$router.push({name: 'DealCalculator', params: {id: this.item.id}})
    },
    copyToClipboard() {
      const address = `${window.location.origin}/deal/${this.item.id}/preview`;
      navigator.clipboard.writeText(address).then(() => {
        this.$message({
          message: 'Адрес объявления успешно скопирован',
          type: 'success'
        });
      }).catch(err => {
        console.log('Ошибка при копировании адреса', err);
      });
    }
  }
};
</script>

<style scoped>
  .disabled {
    text-decoration: line-through;
  }
  .reasons-cant-trade {
    display: flex;
    flex-direction: column;
  }
</style>
