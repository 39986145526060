<template lang="pug">
  router-link.d-flex.user-profile-link(:to="{ name: 'Profile', params: { id: user.id } }")
    img(:src='user.avatar || defaultAvatar', :alt="user.login")
    .ml-2
      span.card-product-new__title {{user.login}}
      .star-rating
        span.star-rating__star(v-for="number in 5", :class="{active: user.trust_coef >= number * 20}")
</template>

<script>

export default {
  name: 'UserCardSmall',

  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      defaultAvatar: '/img/default-avatar.png',
    }
  }
};
</script>

<style>
  .user-profile-link {
    cursor: pointer;
    text-decoration: none;
  }
  .card-product-new__title {
    font-size: 15px;
  }

  .previewDeal-header__character-text {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>