export default {
  data() {
    return {
      MAX_PAYMENTS_VISIBLE: 1,
    };
  },

  computed: {
    //
    user() {
      return this.item.author;
    },

    //
    payments() {
      return this.item.payments.slice(0, this.MAX_PAYMENTS_VISIBLE);
    },

    //
    hasManyPayments() {
      return this.item.payments.length > this.MAX_PAYMENTS_VISIBLE;
    },

  },

  methods: {
    //
    iconBg(icon) {
      return `background: url("${icon}") no-repeat center; background-size: 100% auto;`;
    },

    //
    btnLabel(item) {
      return item.is_sale ? 'Купить' : 'Продать';
    },
  },
}
