<template lang="pug">
  div
    main.main-content.main-content_no-top.layout-main__centering.container.pb-5(:class="{'main-content__left': is_profile}")
      .user-reviews
        .user-reviews__header.wrapper_gray-border
          img(:src="avatar(user.avatar)", width="40", height="40", v-if="show_avatar")
          .user-reviews__user-details(v-if="show_avatar")
            p {{user.login}}
            .star-rating
              span.star-rating__star(v-for="number in 5", :class="{active: user.trust_coef >= number * 20}")
          h5(v-if="!show_avatar") Отзывы
          .tab.user-reviews__tab
            span.tablinks(
              v-for='tab in tabs',
              :key='tab.id',
              :class="{'active': tab.id===activeTab}",
              @click='changeTab(tab.id)'
            ) {{tab.title}} {{tab.quantity}}

          .user-reviews__user-rating
            span {{user.trust_coef}}%
            p {{tabs[0].quantity}} {{noun(tabs[0].quantity, 'отзыв', 'отзыва', 'отзвывов')}}
        div
          div.tabcontent(
            v-for='tab in tabs',
            :key='tab.id',
            :class="{'active': tab.id===activeTab}"
          )
            review-list(:type="tab.id", @contentDownloaded="(value)=>{ setQuantity(value, tab) }")
</template>

<script>
  import utilsMixin from '@/components/mixins/utils';
  import {mapGetters} from 'vuex';
  import {CLIENT_DETAILS} from '@/store/actions/client';
  import ReviewList from '@/components/Profile/ReviewList.vue';

  export default {
    name: 'Reviews',
    components: {ReviewList},
    mixins: [utilsMixin],
    props:{
      show_avatar:{
        type: Boolean,
        default: true,
      },
      is_profile:{
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeTab: 'all',
        tabs: [
          {id: 'all', title: 'Все', quantity: 0},
          {id: 'positive', title: 'Положительные', quantity: 0},
          {id: 'negative', title: 'Отрицательные', quantity: 0},
          {id: 'neutral', title: 'Нейтральные', quantity: 0},
        ],
      }
    },
    computed: {
      ...mapGetters({
        'user': 'getDetails',
        'reviews': 'getReviews',
        'avatar': 'getAvatar',
      }),
      userId() {
        return this.$route.params.id;
      },
    },
    watch: {
      userId: {
        handler: 'getInfo',
        immediate: true,
      },
    },
    mounted() {
      this.getInfo();
    },
    methods: {
      getInfo() {
        this.$store.dispatch(CLIENT_DETAILS, {id: this.userId});
        return this;
      },
      changeTab(id) {
        this.activeTab = id;
        return this;
      },
      setQuantity(quantity, tab) {
        this.tabs[this.tabs.indexOf(tab)].quantity = quantity;
      }
    },
  };
</script>

<style scoped>
  .main-content_no-top {
    top: 0;
  }
  .active {
    display: block;
  }
  .main-content__left{
    margin-left: 0;
    padding-left: 0;
  }
</style>
