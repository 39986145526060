<template lang="pug">
  .profile-container.profile-container_short.wrapper_gray-border
    ProfileGeneralInfo(:user="user")
    ProfileTransactionTurnover
</template>
<script>
  import ProfileGeneralInfo from '@/components/Profile/ProfileGeneralInfo';
  import ProfileTransactionTurnover from '@/components/Profile/ProfileTransactionTurnover';

export default {
  components: {
    ProfileGeneralInfo,
    ProfileTransactionTurnover,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {},
};
</script>
<style>

</style>
