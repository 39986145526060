import utils from './utils';
import {DASHBOARD_ADS_ALL} from '../../store/actions/dashboard';
import {ADS_BUYERS, ADS_SELLERS} from "../../store/actions/markets";

export default {
  mixins: [
    utils,
  ],

  computed: {
    // implement this.itemsRaw in child component
    items() {
      const adFormatted = this.formatAds(this.itemsRaw);
      return this.formatPaymentSystems(adFormatted);
    },

    // implement this.paginationRaw in child component
    pages() {
      return this.paginationRaw || false;
    },

    hasPagination() {
      return !!this.pages && this.pages.total_pages > 1;
    },
  },

  methods: {
    // implement this.filterAction in child component
    changeCurrent(page) {
      return this.$store.dispatch(this.filterAction, { page });
    },

    //
    formatAds(ads) {
      const adsNew = JSON.parse(JSON.stringify(ads));
      let filteredAds = [];
      if (this.action === DASHBOARD_ADS_ALL || this.action === ADS_SELLERS || this.action === ADS_BUYERS) {
        filteredAds = adsNew.filter(ad => {
          return (!this.filterSource.price_from || ad.price >= this.filterSource.price_from)
          && (!this.filterSource.price_to || ad.price <= this.filterSource.price_to)
          && (!this.filterSource.min_from || ad.min >= this.filterSource.min_from)
          && (!this.filterSource.max_to || ad.max <= this.filterSource.max_to)
          && (!this.filterSource.reputation_from || ad.author?.trust_coef >= this.filterSource.reputation_from)
          && (!this.filterSource.reputation_to || ad.author?.trust_coef <= this.filterSource.reputation_to)
          && (!this.filterSource.payment_system_id || ad.payment_system.id === this.filterSource.payment_system_id)
          && (!this.filterSource.currency_id || ad.currency.id === this.filterSource.currency_id)
          && (!this.filterSource.country_id || ad.country_id === this.filterSource.country_id)
        });
      } else {
        filteredAds = adsNew;
      }

      return filteredAds.map(ad => {
        ad.price = this.thousandSeparator(ad.price);
        ad.min = this.thousandSeparator(ad.min);
        ad.max = this.thousandSeparator(ad.max);
        ad.currency.code = ad.currency.code.toUpperCase();
        ad.crypto_currency.code = ad.crypto_currency.code.toUpperCase();
        ad.created_at = this.divideDate(ad.created_at).date;

        return ad;
      });
    },

    //
    formatPaymentSystems(ads) {
      return ads.map(ad => {
        if (ad.banks && ad.banks.length > 0) {
          ad.payments = [
            {
              title: ad.payment_system.title,
              icon: ad.payment_system.icon,
            },
            ...ad.banks
          ];
        } else {
          ad.payments = [
            {
              title: ad.payment_system.title,
              icon: ad.payment_system.icon,
            },
          ];
        }

        return ad;
      });
    },
  },
};
