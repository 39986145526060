<template lang="pug">
.profile-transaction-turnover(v-loading="isLoading")
  h3 Оборот
  .profile-transaction-turnover__data
    .profile-transaction-turnover__data__block(v-for="current in turnover.data")
      .profile-transaction-turnover__data__block-name {{ current.code }}
      .profile-transaction-turnover__data__block-number {{ current.turnover }}
    .profile-transaction-turnover__data__block
      .profile-transaction-turnover__data__block-name USD
      .profile-transaction-turnover__data__block-number ${{ turnover.turnoverInUSD }}
</template>

<script>
import { mapGetters } from 'vuex';
import { CLIENT_TURNOVER } from '@/store/actions/client.js';

export default {
  name: 'ProfileTransactionTurnover',
  computed: {
    ...mapGetters({
      'loading': 'loading',
      'turnover': 'getTurnover',
    }),
    isLoading() {
      return this.loading(CLIENT_TURNOVER) === 'loading';
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-transaction-turnover {
  width: 100%;

  h3 {
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    color: $gray800;
    margin-bottom: 10px;
  }

  &__data {
    &__block {
      display: flex;
      gap: 12px;

      margin-bottom: 8px;
    }

    &__block-name {
      color: $gray600;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
</style>
