<template lang="pug">
  .profile-block-offer_inner(
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
  )
    span.profile-block-offer__title Купить криптовалюту у пользователя
    AdList(v-if="sellList.length > 0" :itemsRaw="sellList" :user="user" :is_sale="true")
    .profile-block-offer(v-else) Нет объявлений
    hr
    span.profile-block-offer__title Продать криптовалюту пользователю
    AdList(v-if="buyList.length > 0" :itemsRaw="buyList" :user="user" :is_sale="false")
    .profile-block-offer(v-else) Нет объявлений
</template>
<script>
  import {mapGetters} from 'vuex';
  import {CLIENT_ADS} from '@/store/actions/client';
  import AdList from '@/components/Profile/AdList';

  export default {
    components: {
      AdList,
    },
    props: {
      user: {
        type: Object,
        default() {
          return {};
        },
      }
    },
    data() {
      return {
        type: 'for_sale',
      }
    },
    computed: {
      ...mapGetters([
        'loading',
        'marketInfo',
      ]),
      sellList() {
        return this.$store.state.client.ads['for_sale'];
      },
      buyList() {
        return this.$store.state.client.ads['for_buy'];
      },
      isLoading() {
        return this.loading(CLIENT_ADS) === 'loading';
      },
    },
    mounted() {
      this.getAds('for_sale');
      this.getAds('for_buy');
    },
    methods: {
      getAds(type) {
        let data = {
          id: this.user.id,
          params: {
            page: 1
          },
          type,
        };
        data.params[type] = 1;
        this.$store.dispatch(CLIENT_ADS, data);
      },
    }
  }
</script>
<style>
  .profile-block-offer_inner {
    max-width: 100%;
  }

  .profile-block-offer {
    grid-template-columns: repeat(3, 31%);
  }

  .card-product__reviews span.cursor-link {
    font-size: 8px;
    color: #7E7E7E;
    margin-bottom: 0;
  }
</style>
