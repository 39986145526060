<template lang="pug">
  div(
  v-loading='isLoading',
  element-loading-text='Загрузка данных...',
  )
    .user-reviews-list
      span(v-if="data.length===0") Отзывы отсутствуют
      .user-reviews-item(
        v-for='review in currentReviews',
        :key='review.id'
      )
        img(:src="avatar(review.from_user.avatar)", width="40", height="40")
        .user-reviews-item__content
          .user-reviews-item__text
            span {{review.from_user.login}}
            p {{review.message}}
          .user-reviews-item__info
            time {{divideDate(review.created_at).date}} {{divideDate(review.created_at).time}}
            span.user-reviews-item__smile.user-reviews-item__emoji(:class="review.trust")
      .show-more__container(v-if="displayShowMoreButton")
        button.btn.btn-outline-success.btn-sm(@click="isShowMore = true") Ещё
      el-pagination(
      class='reviews-pagination',
      v-if='hasPagination',
      :total='pages.total',
      :page-size='parseInt(pages.per_page)',
      :current-page='pages.current_page',
      :disabled='isLoading'
      background,
      layout='prev, pager, next',
      @current-change='changeCurrent',
      )

</template>

<script>
  import utilsMixin from '../mixins/utils';
  import {mapGetters} from 'vuex';
  import {AD_USER_REVIEWS} from '../../store/actions/ad';
  import cfg from '../../../config';

  export default {
    name: 'ReviewList',
    mixins: [utilsMixin],
    props: {
      type: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        data: [],
        pages: {
          page: 1
        },
        isShowMore: false,
      }
    },
    computed: {
      ...mapGetters({
        'reviews': 'getReviews',
        'avatar': 'getAvatar',
        'loading': 'loading',
      }),
      isLoading() {
        return this.loading(AD_USER_REVIEWS) === 'loading';
      },
      userId() {
        return this.$route.params.id;
      },
      hasPagination() {
        return !!this.pages && this.pages.total_pages > 1 && this.isShowMore;
      },
      displayShowMoreButton() {
        return !this.isShowMore && this.data.length > 3;
      },
      currentReviews() {
        if (!this.data) return;
        return this.isShowMore ? this.data : this.data.slice(0, 3);
      }
    },
    mounted() {
      this.getData(this.pages.page);
    },
    methods: {
      changeCurrent(page) {
        this.getData(page);
      },
      getData(page) {
        let filter = {
          trust: this.type,
          per_page: parseInt(cfg.per_page_min),
          page: page
        };
        if (this.type === 'all') {
          filter = { per_page: parseInt(cfg.per_page_min), page: page };
        }
        this.$store.dispatch(AD_USER_REVIEWS, {userId: this.userId, filter}).then(() => {
          this.data = this.reviews.data;
          this.pages = this.reviews.pagination;
          this.$emit('contentDownloaded', this.pages.total);
        });
        return this;
      },
      changePage(page) {
        page = page || 1;
        this.getData(page);
      },
      prev() {
        let page = this.pages.current_page - 1;
        if (this.pageExists(page)) {
          this.changePage(page);
        }
      },
      next() {
        let page = this.pages.current_page + 1;
        if (this.pageExists(page)) {
          this.changePage(page);
        }
      },
      pageExists(page) {
        return page > 0 && page <= this.pages.total_pages
      }
    },
  };
</script>

<style scoped>
  .active {
    display: block;
  }
  .user-reviews-item__smile {
    background: url("/img/smile-good.svg") no-repeat center;
    -webkit-background-size: cover;
    background-size: cover; }

  .user-reviews-item__smile.positive {
    background-image: url("/img/smile-good.svg");
  }

  .user-reviews-item__smile.neutral {
    background-image: url("/img/smile-ok.svg");
  }

  .user-reviews-item__smile.negative {
    background-image: url("/img/smile-bad.svg");
  }

  .user-reviews-list {
    padding: 5px 20px;
  }

  .reviews-pagination {
    text-align: center;
    margin: 15px auto 5px;
  }

  .show-more__container {
    text-align: center;
    margin: 15px auto 5px;
  }
</style>
