<template lang="pug">
  .profile-container__general-info
    .d-flex
      .profile-container__block-img
        img(:src='user.avatar', alt='avatar')
      .profile-container__block-info
        span {{ user.login }}
        .star-rating
          span.star-rating__star(
          v-for="(number, index) in 5",
          :class="{active: user.trust_coef !== 0 && user.trust_coef >= number * 20}"
          )
      .profile-container__block-rightInfo
        //- span.profile-container__block-rightInfo-status Опытный пользователь - никак не считается, был хардкод
    .profile-container__bottom-block
      span Общая информация:
      .profile-container__commonInfo
        ul.profile-container__commonInfo-list
          li.profile-container__commonInfo-item(v-if="user.appeal")
            p Послание продавца:
            span {{user.appeal}}
          li.profile-container__commonInfo-item
            p Суммарный рейтинг:
            span {{`${user.trust_coef}%`}}
          li.profile-container__commonInfo-item
            p На сайте с:
            span {{ $moment(user.created_at, 'YYYY-MM-DD HH:mm:ss ZZ').format('DD.MM.YYYY') }}
          li.profile-container__commonInfo-item
            p Количество сделок:
            span {{ user.statistics.deals_count }}
          li.profile-container__commonInfo-item
            p E-mail:
            span.d-flex.align-items-center
              span.profile-container__commonInfo-confirmation(
                :class="user.email_confirmed ? 'confirmed' : 'unconfirmed'"
              )
              | {{ user.email_confirmed ? 'Подтвержден' : 'Не подтвержден' }}
          li.profile-container__commonInfo-item
            p Личность:
            span.d-flex.align-items-center
              span.profile-container__commonInfo-confirmation(
                :class="user.is_kyc_confirmed ? 'confirmed' : 'unconfirmed'"
              )
              | {{ user.is_kyc_confirmed ? 'Подтверждена' : 'Не подтверждена' }}
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default () {
        return {};
      },
    },
  },
  data () {
    return {};
  },
  computed: {},
  mounted () {},
};
</script>
<style lang="scss" scoped>
  .profile-container {
    &__review {
      cursor: pointer;
    }
    &__block-info {
      word-break: break-word;
      padding-right: 15px;
    }
  }
</style>
