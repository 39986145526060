<template lang="pug">
  main.main-content.layout-main__centering.container.pb-5(v-if='isLoaded')
    div
      ProfileWidget(:user="user")
    div
      Reviews(:show_avatar="false", :is_profile="true")
    div
      ProfileAds(:user="user")
</template>
<script>
  import ProfileWidget from '@/components/Profile/ProfileWidget';
  import ProfileAds from '@/components/Profile/ProfileAds';
  import Reviews from '@/components/Profile/Reviews';
  import {mapGetters} from 'vuex';
  import {CLIENT_DETAILS} from '../store/actions/client.js';

  export default {
    components: {
      ProfileWidget,
      ProfileAds,
      Reviews
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters({
        'loading': 'loading',
        'profile': 'getProfile',
        'user': 'getDetails',
        'userAds': 'getDetailsAds',
        'isAuthenticated': 'isAuthenticated',
      }),
      userId() {
        return this.$route.params.id;
      },
      ratingClass() {
        return parseInt(this.user.trust_coef) > 49 ? 'common-info__item--positive' : 'common-info__item--negative';
      },
      isLoaded() {
        return this.user.id && this.user.id === parseInt(this.userId);
      }
    },
    watch: {
      userId: {
        handler: 'getInfo',
        immediate: true,
      },
      user(val) {
        if (val) {
          this.user.avatar = val.avatar ? val.avatar : '/img/default-avatar.png';
        }
      }
    },
    methods: {
      getInfo() {
        this.$store.dispatch(CLIENT_DETAILS, {id: this.userId});
      },
    },
  }
</script>
