<template lang="pug">
  .profile-block-offer(v-if="items.length")
    AdCard(v-for="item in items", :key="item.id", :item="item")
</template>
<script>
import adUtils from '@/components/mixins/ads';
import AdCard from '@/components/Market/AdCard.vue';

export default {
  components: {
    AdCard,
  },
  mixins: [
    adUtils,
  ],
  props: {
    itemsRaw: {
      type: Array,
      default() {
        return {};
      },
    },
    user: {
      type: Object,
      default() {
        return {};
      },
    },
    is_sale: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    canTrade(item) {
      return item.hasOwnProperty('is_deal_available') ? item.is_deal_available.result : true;
    },
    reasonsCantTrade(item) {
      return item.is_deal_available.messages;
    },
  },
};
</script>
<style>
  .card-product__icon-bank img {
    width: 100%;
  }
</style>
